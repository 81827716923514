import { formatUrl } from 'lib/formatUrl'
import Link from 'next/link'

import { ChevronRightIcon } from '@heroicons/react/solid'

type Props = {
  id?: string
  url: string
  title: string
  intro: string
  isExternalUrl?: boolean
}

const LinkRectTitleIntroArrow = ({ id, url, title, intro, isExternalUrl }: Props) => {
  const formattedUrl = formatUrl(url)
  return (
    <div className="flex w-full" id={id}>
      <Link href={formattedUrl} passHref>
        <a target={isExternalUrl ? '_blank' : ''}>
          <div className="flex w-full max-w-2xl cursor-pointer gap-4 border-t-2 border-tella-blue-300 px-4 py-4 transition-colors duration-300 hover:bg-tella-blue-100">
            <div className="flex w-full flex-col gap-4 md:flex-row">
              <h3 className="font-semibold md:w-52">{title}</h3>
              <p className="w-full">{intro}</p>
            </div>
            <span
              className={`flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-tella-blue-300 sm:h-14 sm:w-14 `}
            >
              <ChevronRightIcon className="h-6 w-6 text-white sm:h-8 sm:w-8" />
            </span>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default LinkRectTitleIntroArrow
