import { formatUrl } from 'lib/formatUrl'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import Link from 'next/link'

type Props = {
  id?: string
  url: string
  title: string
  intro: string
  image: string
  isExternalUrl?: boolean
}
const LinkRectImageTitleIntroBorderTop = ({ id, url, title, intro, image, isExternalUrl }: Props) => {
  const formattedUrl = formatUrl(url)

  return (
    <>
      {formattedUrl === '/' ? (
        <div
          className="group flex h-96 w-64 flex-col overflow-hidden border-t-2 border-tella-blue-300 py-6 sm:w-80"
          id={id}
        >
          <h3 className="flex h-1/6 items-start font-semibold">{title}</h3>
          <div className="flex h-2/6">{intro}</div>
          <div className="flex h-3/6 justify-end">
            <div className="mx-auto flex w-52 scale-125 items-center justify-center sm:mx-0">
              {stringToImgComponent(image)}
            </div>
          </div>
        </div>
      ) : (
        <Link href={formattedUrl} passHref>
          <a target={isExternalUrl ? '_blank' : ''}>
            <div
              className="group flex h-96 w-64 cursor-pointer flex-col overflow-hidden border-t-2 border-tella-blue-300 py-6 sm:w-80"
              id={id}
            >
              <h3 className="flex h-1/6 items-start font-semibold">{title}</h3>
              <div className="flex h-2/6">{intro}</div>
              <div className="flex h-3/6 justify-end">
                <div className="mx-auto flex w-52 scale-125 items-center justify-center transition-transform duration-300 group-hover:scale-150 sm:mx-0">
                  {stringToImgComponent(image)}
                </div>
              </div>
            </div>
          </a>
        </Link>
      )}
    </>
  )
}

export default LinkRectImageTitleIntroBorderTop
