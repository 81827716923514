export type BtnStyles = 'Blue' | 'Pink' | 'Green'

type Props = {
  text: string | React.ReactNode
  value?: string | number
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  on?: boolean
  disable?: boolean
  style: BtnStyles
  containerClass?: string
  width?: string
  type?: 'submit' | 'button'
}

const classes = {
  blue: 'bg-tella-blue-300 text-white',
  'light-blue': 'bg-tella-blue-100 text-tella-blue-300',
  pink: 'bg-tella-pink-300 text-white',
  'light-pink': 'bg-tella-light-pink-200 text-tella-blue-300',
  green: 'bg-tella-green-300 text-tella-blue-300',
}

const Button = ({ text, value, onClick, on, disable, style, containerClass, width, type = 'button' }: Props) => {
  return (
    <button
      type={type}
      value={value}
      className={`h-10 rounded-full shadow hover:opacity-80 active:opacity-100 ${width ? width : 'w-full px-3'} ${
        containerClass ?? ''
      } ${
        style === 'Blue'
          ? `${on ? classes['light-blue'] : classes.blue}`
          : style === 'Pink'
          ? `${on ? classes['light-pink'] : classes.pink}`
          : classes.green
      }`}
      onClick={
        disable
          ? () => {
              return
            }
          : onClick
      }
    >
      <span className="block overflow-hidden whitespace-nowrap">{text}</span>
    </button>
  )
}

export default Button
