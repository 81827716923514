import { formatUrl } from 'lib/formatUrl'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import Link from 'next/link'

type Props = {
  id?: string
  url: string
  title: string
  image: string
  isExternalUrl?: boolean
}

const LinkRectImageTitleStandard = ({ id, url, title, image, isExternalUrl }: Props) => {
  const formattedUrl = formatUrl(url)
  return (
    <Link href={formattedUrl} passHref>
      <a target={isExternalUrl ? '_blank' : ''}>
        <div
          className="mb-5 mr-6 flex h-72 w-60 cursor-pointer flex-col items-stretch justify-between rounded-md bg-tella-blue-100 transition-colors duration-300 hover:bg-tella-light-pink-300"
          id={id}
        >
          <div className="flex h-2/3 items-center justify-center">{stringToImgComponent(image)}</div>
          <div className="flex h-1/3 items-end p-4">
            <h4 className="font-semibold">{title}</h4>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default LinkRectImageTitleStandard
