import { formatUrl } from 'lib/formatUrl'
import Link from 'next/link'

import { LinkIcon } from '@heroicons/react/outline'

type Props = {
  id?: string
  url: string
  title: string
  intro: string
  isExternalUrl?: boolean
}

const LinkRectImageTitleIntroThin = ({ id, url, title, intro, isExternalUrl }: Props) => {
  const formattedUrl = formatUrl(url)
  return (
    <div className="mb-4 w-full" id={id}>
      <Link href={formattedUrl} passHref>
        <a target={isExternalUrl ? '_blank' : ''}>
          <div className="flex h-20 max-w-2xl cursor-pointer items-center justify-between rounded-lg bg-white p-3 hover:shadow">
            <div className="flex items-center gap-4">
              <div className="flex min-w-fit rounded-full bg-tella-blue-300 p-2 text-white">
                <LinkIcon height={18} width={18} />
              </div>
              <div className="flex flex-col">
                <p className="font-semibold line-clamp-1">{title}</p>
                <p className="line-clamp-1">{intro}</p>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default LinkRectImageTitleIntroThin
