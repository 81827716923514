import LinkRectImageTitleIntroBorderTop from 'components/links/link-rect-image-title-intro-border-top'
import LinkRectImageTitleIntroStandard from 'components/links/link-rect-image-title-intro-standard'
import LinkRectImageTitleIntroThin from 'components/links/link-rect-image-title-intro-thin'
import LinkRectImageTitleStandard from 'components/links/link-rect-image-title-standard'
import LinkRectTitleIntroArrow from 'components/links/link-rect-title-into-arrow'
import LinkSqrImageTitleOut from 'components/links/link-sqr-image-title-out'
import * as href from 'lib/constants/hrefs'
import { Link } from 'types/strapi/item'

type Props = {
  id?: string
  style: string
  isMembersOnly?: boolean
  link: Link
  queryString?: string
}

type LinkProps = { url: string; title: string; image: string; intro: string; isExternalUrl?: boolean }

const StyleToLinkComponent = ({ id, style, isMembersOnly, link, queryString = '' }: Props) => {
  const enumToLink = (link: Link): LinkProps => {
    switch (link.predefinedUrls) {
      case 'Application':
        return {
          url: `${href.HL_APPLICATION}${queryString}`,
          title: link.title || 'Application',
          image: link.image || '',
          intro: link.intro || '',
        }
      case 'ContactUs':
        return {
          url: `${href.CONTACT_US}`,
          title: link.title || 'Contact Us',
          image: link.image || '',
          intro: link.intro || '',
        }
      case 'Home':
        return {
          url: '/',
          title: link.title || 'Home',
          image: link.image || '',
          intro: link.intro || '',
        }
      case 'KiwiSaverProviders':
        return {
          url: `${href.KIWISAVER_PROVIDERS}`,
          title: link.title || 'KiwiSaver Providers',
          image: link.image || '',
          intro: link.intro || '',
        }
      case 'Learn':
        return {
          url: `${href.LEARN}`,
          title: link.title || 'Learn',
          image: link.image || '',
          intro: link.intro || '',
        }
      default:
        return {
          url: '/',
          title: link.title || 'No title',
          image: link.image || '',
          intro: link.intro || '',
        }
    }
  }

  const customLink = (link: Link): LinkProps => {
    if (link.externalUrl) {
      return {
        url: `${link.externalUrl}`,
        title: link.title || link.externalUrl,
        image: link.image,
        intro: link.intro,
        isExternalUrl: true,
      }
    } else if (link.guideCategory?.data)
      return {
        url: `/home-loans-mortgages/${link.guideCategory.data.attributes.urlSlug}`,
        title: link.title || link.guideCategory.data.attributes.title,
        image: link.image || link.guideCategory.data.attributes?.icon,
        intro:
          link.intro || link.guideCategory.data.attributes?.shortIntro || link.guideCategory.data.attributes?.intro,
      }
    else if (link.websitePage?.data)
      return {
        url: `/${link.websitePage.data.attributes.urlSlug}`,
        title: link?.title || link.websitePage.data.attributes.pageTitle || 'Title',
        image: link.image || link.websitePage.data.attributes.hero?.image,
        intro:
          link.intro || link.websitePage.data.attributes.shortIntro || link.websitePage.data.attributes.hero?.intro,
      }
    else if (link.guide?.data)
      return {
        url: `/home-loans-mortgages/${link.guide.data.attributes.category.data?.attributes.parent.data?.attributes.urlSlug}/${link.guide.data.attributes.urlSlug}`,
        title: link?.title || link.guide.data.attributes.title,
        image: link.image || link.guide.data.attributes.icon,
        intro:
          link.intro ||
          link.guide.data.attributes.shortIntro ||
          link.guide.data.attributes?.intro ||
          link.guide.data.attributes.hero?.intro,
      }
    else if (link.predefinedUrls) {
      return enumToLink(link)
    } else return { url: '/', title: link.title || 'No Title', image: link.image, intro: link.intro }
  }
  switch (style) {
    case 'LinkRectImageTitleStandard':
      return (
        <LinkRectImageTitleStandard
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          image={customLink(link).image}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    case 'LinkSqrImageTitleOut':
      return (
        <LinkSqrImageTitleOut
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          image={customLink(link).image}
          isMembersOnly={isMembersOnly}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    case 'LinkRectTitleIntroArrow':
      return (
        <LinkRectTitleIntroArrow
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          intro={customLink(link).intro}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    case 'LinkRectImageTitleIntroStandard':
      return (
        <LinkRectImageTitleIntroStandard
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          image={customLink(link).image}
          intro={customLink(link).intro}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    case 'LinkRectImageTitleIntroThin':
      return (
        <LinkRectImageTitleIntroThin
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          intro={customLink(link).intro}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    case 'LinkRectImageTitleIntroBorderTop':
      return (
        <LinkRectImageTitleIntroBorderTop
          id={id}
          url={customLink(link).url}
          title={customLink(link).title}
          image={customLink(link).image}
          intro={customLink(link).intro}
          isExternalUrl={customLink(link).isExternalUrl}
        />
      )
    default:
      return (
        <div>
          {customLink(link).url}
          <br />
          {customLink(link).title}
          <br />
          {customLink(link).intro}
        </div>
      )
  }
}

export default StyleToLinkComponent
