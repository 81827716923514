import { Link, Review, Team, Text, TextAndImage, TextWithImage, TextWithImageAndButton, Universal } from './item'

export type ContentBlock = {
  id: number
  __component: string
}

export type ContentBlockText = ContentBlock & {
  heading: string
  text: string
}

export type ContentBlockTexts = ContentBlock & {
  heading: string
  texts: Text[]
}

export type ContentBlockKeyFacts = ContentBlock & {
  heading: string
  intro: string
  details: string
  keyFacts: {
    id: number
    heading: string
    intro: string
    details: string
  }[]
}

export type ContentBlockFaqs = ContentBlock & {
  heading: string
  faqs: {
    id: number
    question: string
    answer: string
  }[]
}

export type ContentBlockLinks = ContentBlock & {
  heading: string
  intro: string
  style: string
  links: Link[]
}

export type ContentBlockVideo = ContentBlock & {
  heading: string
  youtubeUrl: string
}

export type ContentBlockImages = ContentBlock & {
  style: string
  images: {
    data: {
      attributes: {
        name: string
        url: string
      }
    }[]
  }
}

export type ContentBlockTextsWithImage = ContentBlock & {
  heading: string
  intro: string
  style: string
  textsWithImage: TextWithImage[]
}

export type ContentBlockRichText = ContentBlock & {
  text: string
}

export type ContentBlockTextWithImageAndButton = ContentBlock & TextWithImageAndButton

export type ContentBlockUniversals = ContentBlock & {
  heading: string
  headIntro: string
  style: string
  universals: Universal[]
}

export type ContentBlockTeams = ContentBlock & {
  heading: string
  headIntro: string
  style: string
  members: Team[]
}

export type ContentBlockReviews = ContentBlock & {
  heading: string
  reviews: Review[]
  colorTitle: string
  intro: string
  type: string
}

export type ContentBlockTextAndImage = ContentBlock & {
  heading: string
  colorTitle: string
  intro: string
  type: string
  mortgageSubComponent: TextAndImage[]
  sectionImage: {
    data: {
      attributes: {
        name: string
        url: string
      }
    }
  }
}

export type ContentBlockDepositPercentageOption = ContentBlock & {
  firstPercentageOption: number
  secondPercentageOption: number
  thirdPercentageOption: number
}

export type contentBlocks =
  | ContentBlockText
  | ContentBlockTexts
  | ContentBlockKeyFacts
  | ContentBlockFaqs
  | ContentBlockLinks
  | ContentBlockVideo
  | ContentBlockImages
  | ContentBlockTextsWithImage
  | ContentBlockRichText
  | ContentBlockTextWithImageAndButton
  | ContentBlockUniversals
  | ContentBlockReviews
  | ContentBlockTextAndImage
  | ContentBlockDepositPercentageOption
  | ContentBlockTeams

export const isContentBlockText = (contentBlock: contentBlocks): contentBlock is ContentBlockText => {
  return (contentBlock as ContentBlock).__component === 'items.text'
}

export const isContentBlockTexts = (contentBlock: contentBlocks): contentBlock is ContentBlockTexts => {
  return (contentBlock as ContentBlock).__component === 'blocks.texts'
}

export const isContentBlockKeyFacts = (contentBlock: contentBlocks): contentBlock is ContentBlockKeyFacts => {
  return (contentBlock as ContentBlock).__component === 'blocks.key-facts'
}

export const isContentBlockFaqs = (contentBlock: contentBlocks): contentBlock is ContentBlockFaqs => {
  return (contentBlock as ContentBlock).__component === 'blocks.faqs'
}

export const isContentBlockLinks = (contentBlock: contentBlocks): contentBlock is ContentBlockLinks => {
  return (contentBlock as ContentBlock).__component === 'blocks.links'
}

export const isContentBlockVideo = (contentBlock: contentBlocks): contentBlock is ContentBlockVideo => {
  return (contentBlock as ContentBlock).__component === 'blocks.video'
}

export const isContentBlockImages = (contentBlock: contentBlocks): contentBlock is ContentBlockImages => {
  return (contentBlock as ContentBlock).__component === 'blocks.images'
}

export const isContentBlockTextsWithImage = (
  contentBlock: contentBlocks
): contentBlock is ContentBlockTextsWithImage => {
  return (contentBlock as ContentBlock).__component === 'blocks.texts-with-image'
}

export const isContentBlockRichText = (contentBlock: contentBlocks): contentBlock is ContentBlockRichText => {
  return (contentBlock as ContentBlock).__component === 'blocks.rich-text'
}

export const isContentBlockTextWithImageButton = (
  contentBlock: contentBlocks
): contentBlock is ContentBlockTextWithImageAndButton => {
  return (contentBlock as ContentBlock).__component === 'items.text-with-image-button'
}

export const isContentBlockUniversals = (contentBlock: contentBlocks): contentBlock is ContentBlockUniversals => {
  return (contentBlock as ContentBlock).__component === 'blocks.universals'
}

export const isContentBlockReviews = (contentBlock: contentBlocks): contentBlock is ContentBlockReviews => {
  return (contentBlock as ContentBlock).__component === 'blocks.reviews'
}

export const isContentBlockTextAndImage = (contentBlock: contentBlocks): contentBlock is ContentBlockTextAndImage => {
  return (contentBlock as ContentBlock).__component === 'blocks.text-and-image'
}

export const isContentBlockTeams = (contentBlock: contentBlocks): contentBlock is ContentBlockTeams => {
  return (contentBlock as ContentBlock).__component === 'blocks.teams'
}
