import { formatUrl } from 'lib/formatUrl'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import Link from 'next/link'

import { LockClosedIcon } from '@heroicons/react/solid'

type Props = {
  id?: string
  url: string
  title: string
  image: string
  isMembersOnly?: boolean
  isExternalUrl?: boolean
}

const LinkSqrImageTitleOut = ({ id, url, title, image, isMembersOnly, isExternalUrl }: Props) => {
  const formattedUrl = formatUrl(url)
  return (
    <Link href={formattedUrl} passHref>
      <a target={isExternalUrl ? '_blank' : ''}>
        <div className="group relative mb-5 mr-5 w-64 cursor-pointer" id={id}>
          <div className="flex h-64 w-64 items-center justify-center rounded-md bg-tella-blue-100 transition-shadow duration-300 group-hover:shadow-lg ">
            {stringToImgComponent(image)}
          </div>
          {isMembersOnly && (
            <div className="absolute right-3 top-3 flex gap-1 rounded-full bg-tella-pink-300 py-1 pl-2 pr-1.5 text-xs text-white shadow-md">
              Member <LockClosedIcon width={14} height={14} />
            </div>
          )}
          <p className="mt-2 font-semibold line-clamp-2">{title}</p>
        </div>
      </a>
    </Link>
  )
}

export default LinkSqrImageTitleOut
