import { formatUrl } from 'lib/formatUrl'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import Link from 'next/link'
import { useRouter } from 'next/router'

type Props = {
  id?: string
  url: string
  title: string
  intro: string
  image: string
  isExternalUrl?: boolean
}

const LinkRectImageTitleIntroStandard = ({ id, url, title, intro, image, isExternalUrl }: Props) => {
  const router = useRouter()
  const formattedUrl = formatUrl(url)
  return (
    <div className="h-30 mb-4 w-full" id={id}>
      <Link href={formattedUrl} passHref>
        <a target={isExternalUrl ? '_blank' : ''}>
          <div
            className="flex max-w-2xl cursor-pointer rounded-md bg-tella-blue-100 hover:shadow"
            onClick={() => (isExternalUrl ? window.open(url) : router.push(url))}
          >
            <div className="flex w-3/4 flex-col justify-between p-4">
              <p className="text-tella-pink-300">{title}</p>
              <p className="font-semibold">{intro}</p>
            </div>
            <div className="w-1/4">{stringToImgComponent(image)}</div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default LinkRectImageTitleIntroStandard
